<template>
  <div>
    <AccordionCard :state="cards.cableEntryDoneProperlyState">
      <template v-slot:header>
        {{ $t('cableEntryDoneProperlyState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableEntryDoneProperlyState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cableEntryDoneProperlyState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cableEntryDoneProperlyNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[62].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.cableEntryDoneProperlyAttachments"
              attachmentType="cableEntryDoneProperlyAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCableEntryDoneProperlyAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="cableEntryDoneProperlyAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isCableEntryDoneProperlyAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cablesProtectedFromUvState">
      <template v-slot:header>
        {{ $t('cablesProtectedFromUvState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cablesProtectedFromUvState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.cablesProtectedFromUvState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.cablesProtectedFromUvNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[63].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.cablesProtectedFromUvAttachments"
              attachmentType="cablesProtectedFromUvAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCablesProtectedFromUvAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="cablesProtectedFromUvAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isCablesProtectedFromUvAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.pvStringCableProperlySizedState">
      <template v-slot:header>
        {{ $t('pvStringCableProperlySizedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('pvStringCableProperlySizedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.pvStringCableProperlySizedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.pvStringCableProperlySizedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.stringCableLabelingState">
      <template v-slot:header>
        {{ $t('stringCableLabelingState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('stringCableLabelingState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.stringCableLabelingState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.stringCableLabelingNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[67].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>

          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.stringCableLabelingAttachments"
              attachmentType="stringCableLabelingAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isStringCableLabelingAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="stringCableLabelingAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isStringCableLabelingAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.peCableProperlySizedState">
      <template v-slot:header>
        {{ $t('peCableProperlySizedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('peCableProperlySizedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.peCableProperlySizedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.peCableProperlySizedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.flatRoofcablesProperlySecuredState">
      <template v-slot:header>
        {{ $t('flatRoofcablesProperlySecuredState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('flatRoofcablesProperlySecuredState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.flatRoofcablesProperlySecuredState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.latRoofcablesProperlySecuredNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.isConnectorProperlyPluggedState">
      <template v-slot:header>
        {{ $t('isConnectorProperlyPluggedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isConnectorProperlyPluggedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isConnectorProperlyPluggedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isConnectorProperlyPluggedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[71].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.correctSelectionOfPvConnectorsAttachments"
              attachmentType="correctSelectionOfPvConnectorsAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isCorrectSelectionOfPvConnectorsAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="correctSelectionOfPvConnectorsAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isCorrectSelectionOfPvConnectorsAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.isConnectorSealingIntact2State">
      <template v-slot:header>
        {{ $t('isConnectorSealingIntact2State') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isConnectorSealingIntact2State') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isConnectorSealingIntact2State"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isConnectorSealingIntact2Note"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.isCableRoutedThroughChimneyState">
      <template v-slot:header>
        {{ $t('isCableRoutedThroughChimneyState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isCableRoutedThroughChimneyState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isCableRoutedThroughChimneyState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isCableRoutedThroughChimneyNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.isChimneySweeperApprovalGivenState">
      <template v-slot:header>
        {{ $t('isChimneySweeperApprovalGivenState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isChimneySweeperApprovalGivenState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isChimneySweeperApprovalGivenState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isChimneySweeperApprovalGivenNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">{{ $t('image_upload') }}</div>
          <div class="flex-row">
            <div class="flex-column select">
              <div>
                {{ $t('templateAttachments') }}:
                <AttachmentGallery
                  :attachments="getReportTemplateImages[84].templateAttachments"
                  :deletable="false"
                  :sortByName="false"
                  :showFilename="false"
                ></AttachmentGallery>
              </div>
            </div>
          </div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.flatRoofLightningProtectionPresentAttachments"
              attachmentType="flatRoofLightningProtectionPresentAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isFlatRoofLightningProtectionPresentAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="flatRoofLightningProtectionPresentAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="
                isFlatRoofLightningProtectionPresentAttachmentsUploadComplete = false
              "
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.isWallPassageProperlySealedState">
      <template v-slot:header>
        {{ $t('isWallPassageProperlySealedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('isWallPassageProperlySealedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.isWallPassageProperlySealedState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.isWallPassageProperlySealedNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.crimpingProperlyDoneState">
      <template v-slot:header>
        {{ $t('crimpingProperlyDoneState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('crimpingProperlyDoneState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.qualityReport.crimpingProperlyDoneState"
            :options="getEnumValues('YesNoNonVerifiable')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
        <div class="flex-row"></div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('annotations') }}
          </div>
          <div class="flex-column select">
            <Textarea
              v-model="project.qualityReport.crimpingProperlyDoneNote"
              :autoResize="true"
              rows="5"
              cols="30"
            />
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';

export default {
  name: 'SubstructureQualityReportAccordions',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isCableEntryDoneProperlyAttachmentsUploadComplete: false,
      isStringCableLabelingAttachmentsUploadComplete: false,
      isCablesProtectedFromUvAttachmentsUploadComplete: false,
      isFlatRoofLightningProtectionPresentAttachmentsUploadComplete: false,
      isCorrectSelectionOfPvConnectorsAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isCableEntryDoneProperlyAttachmentsUploadComplete = true;
          this.isStringCableLabelingAttachmentsUploadComplete = true;
          this.isCablesProtectedFromUvAttachmentsUploadComplete = true;
          this.isFlatRoofLightningProtectionPresentAttachmentsUploadComplete = true;
          this.isCorrectSelectionOfPvConnectorsAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
